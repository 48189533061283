import React from 'react';
import Portfolio from './components/Portfolio'
import 'antd/dist/antd.css';
import './App.css';

function App() {
  return (
    <Portfolio />
  );
}

export default App;
